.header {
    height: 100vh;
    background-image: url('https://wallpapercave.com/wp/wp6686629.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* padding-top: 87.5px;  */
    position: relative;
}

.header .logo {
    margin-top: 50px;
    margin-left: 50px;
    width: 100px;
    height: 100px;
}

.header .logo-text {
    margin-left: 160px;
    margin-top: -115px;
    color: white;
    font-size: 45px;
    text-shadow: 1px 1px black;
}

.header .logo-text .subtext {
    font-size: 30px;
}

.header .coming-soon {
    font-size: 70px;
    margin-top: 100px;
    margin-left: 400px;
}

.footer {
    margin-left: 410px;
    font-size: 15px;
    text-shadow: 1px 1px black;
}
/* https://images-0.rakuten.tv/storage/snapshot/shot/28480e6c-3901-4a93-9f4d-22e510ca0979-snapshot-1590664131.jpeg' */

/* https://wallpapercave.com/wp/wp6686509.jpg
https://hips.hearstapps.com/hmg-prod/images/brokeback-1671806599.png?resize=980:* */